


























import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/common/services/auth-service';
import displayMessage from '@/common/functions/display-message';

@Component
export default class ForgotPasswordConfirmation extends Vue {
  authService!: AuthService;

  password = '';

  isLoading = false;

  created(): void {
    this.authService = new AuthService();
  }

  async resetPassword() {
    this.isLoading = true;

    const message = await this.authService.forgotPasswordConfirmation(
      this.$route.query.token as string,
      this.password
    );

    displayMessage(message.message, message.isError, this.$store);

    if (!message.isError) {
      this.$router.push('/login');
    }

    this.isLoading = false;
  }
}
